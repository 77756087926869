import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from "./material.module";
import { NavBar } from "./navbar/navbar";
import { Footer } from "@shared/footer/footer";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";

@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    FontAwesomeModule,
    FlexModule,
    FlexLayoutModule
  ],
  declarations: [
    NavBar,
    Footer
  ],
  exports: [
    NavBar,
    Footer,
    FlexModule,
    FlexLayoutModule,
    MaterialModule,
    FontAwesomeModule
  ],
})
export class SharedModule {}
