<nav class="navbar-header">


  <button class="nav-name" mat-button routerLink="/"> <img src="assets/logo/logo.png" class="logoImg" />Umpqua
    Strong</button>

  <div class="flex-spacer"></div>

  <a mat-icon-button class="nav-btn-link" href="https://www.facebook.com/Umpquastrong/" target="_blank">
    <fa-icon [icon]="faFacebook" size="lg"></fa-icon>
  </a>

  <a mat-icon-button class="nav-btn-link" href="https://www.instagram.com/umpquastrong/" target="_blank">
    <fa-icon [icon]="faInstagram" size="lg"></fa-icon>
  </a>

  <a mat-icon-button class="nav-btn-link" href="https://twitter.com/umpquastrong" target="_blank">
    <fa-icon [icon]="faTwitter" size="lg"></fa-icon>
  </a>

</nav>