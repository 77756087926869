import {Component} from '@angular/core';

@Component({
  selector: 'cbc-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})
export class Footer {

}
